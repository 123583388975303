<div class="justify-content-center page-content">
  <div class="home-content">
    <div class="access-button">
      <h3>Attribute Authority Manager</h3>
      <div class="row mb-4">
        <div class="col px-0">
          <p>
            Censimento utenti e configurazione attributi per applicazioni del
            Sistema Informativo Sanitario
          </p>
        </div>
        <div class="row mt-4">
          <div class="col">
            <button (click)="loginArpa()" itButton="primary" class="me-2">
              Accedi
            </button>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <p class="text-green center-text"> 
              Accedendo al portale dichiaro di aver letto l'informativa sulla privacy sotto riportata 
            </p>
          </div>
        </div>  
      </div>
    </div>
  </div>
</div>
