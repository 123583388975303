import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TokenService } from "src/app/service/token/token.service";
import { UserService } from "src/app/service/user-service";

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.css"],
})
export class LoginPageComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: TokenService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService.deleteUser();
  }

  loginArpa(): void {
    if (sessionStorage.getItem("access_token")) {
      this.router.navigate(["/home"]);
    } else {
      this.authService.getAuthCode();
    }
  }
}
