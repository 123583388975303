<footer id="custom-footer">
  <div class="container-fluid container-footer">
    <div class="">
      <div class="row container-logo">
        <div class="col-12 col-md-9 container-text">
          <div>
            <p>Direzione Diritti di cittadinanza e coesione sociale</p>
            <p>Via Taddeo Alderotti, 26/n</p>
            <p>50139 FIRENZE</p>
          </div>
        </div>
        <div class="col-12 col-md-3 container-text">
          <div>
            <p>Help desk Lun-Ven: 09:00 - 19:00,</p>
            <p>Sab: 09:00 - 13:00</p>
            <p>
              Numero verde:
              <a href="tel:800558080" class="cell-footer">800 558080</a>
            </p>
            <p>
              mail:
              <a href="mailto:helpsis@regione.toscana.it" class="email-footer"
                >helpsis@regione.toscana.it</a
              >
            </p>
          </div>
        </div>
      </div>
      <div class="row container-copyright">
        <div class="span text-center">
          <div>
            Regione Toscana © 2023 |
            <a
              href="https://www.regione.toscana.it/privacy"
              class="link-footer"
              target="_blank"
              >Privacy</a
            >
            |
            <a
              href="http://www.regione.toscana.it/accessibilita"
              class="link-footer"
              target="_blank"
              >Accessibilità</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
